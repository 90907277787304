body {
  background: url("../assets/bg_image.webp");
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sub-container {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.logo {
  width: 50px;
  height: 50px;
}

.items {
  padding: 25px;
  padding-bottom: 50px;
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  list-style: none;
}

@media only screen and (max-width: 800px) {
  .items {
    grid-template-columns: 1fr;
  }

  .main-container {
    align-items: center;
  }

  .sub-container {
    width: 100%;
    align-items: center;
  }
}
