.footer {
    width: 100%;
    height: 80px;
    padding: 15px;
    display: flex;
    justify-content: flex-end;
    bottom: 0;
    position: fixed;
}

.footer .icon {
    margin: 0 5px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-secondary);
    border-radius: 8px;
}

.footer .icon:hover {
    box-shadow: 0 0 15px 2px var(--color-white);
}

.footer .icon svg {
    color: var(--color-secondary) !important;
}