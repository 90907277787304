.separator{
    color: var(--color-white);
    border-bottom: 1px dotted var(--color-secondary);
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    align-self: flex-start;
}

.separator svg{
  margin-right: 15px;
}