.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    font-size: 15px;
    color: var(--color-green);
    background-color: var(--color-secondary);
    box-shadow: 0 1px 12px rgba(25, 0, 0, 0.25);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.header .logo {
    width: 85px;
    height: auto;
}

.header .button {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid var(--color-green);
    border-radius: 8px;
    margin-left: 5px;
}

.header .button:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.header .button p {
    margin-left: 10px;
    font-weight: bold;
}

.header .button-container {
    display: flex;
}