li {
    border-radius: 8px;
    background-color: var(--color-secondary);
    box-shadow: 0 1px 12px rgba(0,0,0,0.25);
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: var(--color-shape);
    height: 100%;
}

li:hover {
    box-shadow: 0 0 15px 2px var(--color-white);
    transform: translateY(-5%);
}



.item-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.1px solid rgba(0,0,0,0.1);;
    padding: 20px;
    color: var(--color-green);
}

.item-content{
    padding: 20px;
    font-size: 15px;
}