.message{
    position: fixed;
    display: flex;
    padding: 25px;
    border-radius: 8px 0 0 8px;
    right: 0;
    margin-top: 150px;
    color: var(--color-secondary);
    background-color: var(--color-green);
    z-index: 5;
    transform: translate(20px);
}

.message p{
    margin-right: 10px;
    font-weight: bold;
}

.message:hover{
    transform: translate(0px);
}