.modalContainer{
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.modal{
    padding: 25px;
    border-radius: 8px;
    width: 25%;
    min-width: 300px;
    background-color: var(--color-secondary);
}

.modal select{
    padding: 15px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--color-green);
    color: var(--color-shape);
    background-color: var(--color-secondary);
}

.modal button{
    padding: 15px;
    margin-top: 10px;
    border: 1px solid var(--color-green);
    color: var(--color-green);
    width: 100%;
    border-radius: 8px;
    background-color: var(--color-secondary);
}

.modal button:hover{
    background-color: rgba(0,0,0,0.1);
}